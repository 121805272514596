.sg-styles {

  &__table {
    width: 100%;
    margin-top: 3rem;

    &-row {
      padding: 10px;
      border-bottom: 1px solid lightgray;
    }
  }

  &__typography {

    .sg-styles__fonts {
      display: flex;
      justify-content: space-evenly;
    }
  }

  &__btn-submit {
    width: auto;
    height: 39px;
    margin: 10px 10px 5px 10px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(53, 48, 48, 0.1);
    color: white !important;
    cursor: pointer;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-size: 18px;
    padding: 8px 27px;
    justify-content: center;
    vertical-align: center;
    background-color: #2DAAE2 !important;
  }


  &__btn-cancel {
    width: auto;
    height: 39px;
    margin: 10px 10px 5px 10px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(53, 48, 48, 0.1);
    cursor: pointer;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-size: 18px;
    padding: 8px 27px;
    justify-content: center;
    vertical-align: center;
    background-color: #F4F4F4 !important;
    color: #818A91 !important;

  }
}