@import "../../../assets/scss/mixins";;

.layout_top__header {

  @include flex(space-between, center);
  margin: 1rem 2rem;

  .layout_top__logo {
    max-width: 15rem;
    max-height: 15rem;
  }

  .layout_top__logout {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 900px) {
  .layout_top__header {
    flex-direction: column;
  }
}