@use '../../../../assets/scss/index' as s;

.filters {
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;

  &__score-range {
    width: 9.625rem;
    height: 2rem;
    padding: 0.375rem;
    border: none !important;

    .MuiOutlinedInput-root {
      padding: 0 !important;
    }
  }

  &__title {
    font-family: 'Mulish', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: s.$darkGray;
    margin-top: 1.75rem;
  }
}