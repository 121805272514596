@import "../../assets/scss/mixins";

.layout {
  position: relative;
  min-height: 100vh;

  &__main {
    @include flex(center, center);
    margin-top: 2.5rem;

    @media only screen and (max-width: 1400px) {
      justify-content: center;
    }

    @media only screen and (max-width: 900px) {
      flex-direction: column;
    }

    .layout__hero-img {
      max-width: 500px;
      max-height: 500px;
      margin-left: 10rem;

      @media only screen and (max-width: 1400px) {
        margin-left: 0;
      }

      @media only screen and (max-width: 900px) {
        max-width: 200px;
        max-height: 200px;
        margin-top: 2rem;
      }
    }
  }
}