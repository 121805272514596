@use '../../../assets/scss' as s;

.opportunities {

    @media print {
        margin-top: 1.5rem;
    }

    &__fieldset {
        @include s.flexDirection(column);
        padding: 2.5rem 2.5rem 2rem 2.5rem;

        @media print {
            padding: 0.8rem;
        }
    }

    &__fieldset--border {
        border-radius: 1rem;
    }

    &__legend {
        padding: 0.62rem;
        font-size: 1.3rem;
        font-weight: bold;

        @media only screen and (max-width: 992px) {
            font-size: 1.1rem;
        }
    }

    &__values {
        @include s.flexColumnVerAlignment(row, space-evenly);

        @media only screen and (max-width: 992px) {
            @include s.flexColumnHorAlignment(column, center);
        }

        @media only screen and (max-width: 1230px) {
            gap: 5px;
        }
    }

    &__update {
        @include s.flexColumnVerAlignment(column, center);
        text-align: center;
        width: 200px;
        height: 200px;
        border-radius: 50%;

        @media print {
            width: 150px;
            height: 150px;
        }
    }

    &__value {
        font-weight: 800;
        font-size: 3.5rem;
        margin: 0;

        @media print {
            font-size: 2.2rem;
        }
    }

    &__name {
        margin: 0;
        font-weight: bold;
        font-size: 1.6rem;

        @media print {
            font-size: 1.1rem;
        }
    }

    &__summary {
        max-width: 13rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media only screen and (max-width: 992px) {
            margin-top: 1rem;
            align-items: center;
        }
    }

    &__summary > p {
        font-weight: 300;
        font-size: 1.37rem;
        line-height: 2rem;

        @media only screen and (max-width: 992px) {
            font-size: 1.1rem;
            text-align: center;
        }
    }

    &__footer {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.18rem;
        color: s.$darkGray;
        margin-inline: auto;
        width: fit-content;
        margin-top: 2rem;

        @media print {
            font-size: 0.7rem;
        }

        @media only screen and (max-width: 992px) {
            text-align: center;
        }
    }

    &__getStartedButton {
        @include s.flexHorAlignment(center);
    }
}