@use '../../../assets/scss' as s;

.subheader-rep {
    @include s.flex(space-between, center);
    font-size: 0.9rem;
    line-height: 0.5rem;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

    &__report-logo-and-details {
        flex-grow: 1;

        @media only screen and (max-width: 768px) {
            margin-bottom: 2rem;
        }
    }

    &__business-security-report {
        flex-grow: 1;
        margin-bottom: 2rem;

        @media print {
            margin-bottom: 0.5rem;
        }

        @media only screen and (max-width: 992px) {
            margin-bottom: 1rem;
        }
    }

    &__security-rep {
        line-height: 1rem;
        font-size: 1.1rem;

        @media only screen and (min-width: 992px) {
            text-align: center;
        }
        @media only screen and (max-width: 768px) {
            text-align: center;
        }
    }

    &__sr-header {
        font-size: 1.25rem;
        margin: 0 0 0.5rem 0;
    }

    &__timestamp {
        margin: 0;
        padding-bottom: 1rem;
        font-size: 1rem !important;
    }

    &__content {
        font-size: 1rem;
        color: s.$darkGray;

        @media only screen and (max-width: 1150px) {
            font-size: 0.9rem;
        }
    }

    &__content p{
        margin: 10px 0;
    }

    &__sr-content {
        font-style: normal;
        font-weight: 400;
        font-size: 1.1rem;
        color: s.$darkGray;
        margin: 0
    }

    &__qr-reports-code {
        @include s.flexColumnHorAlignment(row, center);
        text-align: center;

        @media only screen and (min-width: 992px) {
            justify-content: space-evenly;
        }

        @media only screen and (max-width: 768px) {
            justify-content: space-evenly;
        }
    }

    &__qr_info_wrapper {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        color: s.$darkGray;
        margin-left: 1rem;

        @media only screen and (max-width: 1150px) {
            font-size: 0.8rem;
        }
    }

    &__qr_info {
        text-align: left;
        line-height: 1.7rem;
        margin: 0;
    }
}

@media print {
    .subheader-rep__security-rep > * {
        line-height: 0.8rem;
        font-size: 0.8rem;
        margin-bottom: 1.5rem;
    }
    .subheader-rep__qr_info_wrapper > * {
        line-height: 1.3rem;
        font-size: 0.8rem;
    }
    .subheader-rep__sr-header {
        font-size: 1.25rem;
        margin: 0 0 0.5rem 0;
    }
}