@use "../../../assets/scss" as s;

.footer {
    margin: 1.5rem 0;

    @media print {
        margin: 0;
    }

    &__wrapper {
        @include s.flexColumnVerAlignment(row, space-between);
        align-items: center;

        @media only screen and (max-width: 767px) {
            @include s.flexDirection(column);
        }

        @media print {
            page-break-after: always;
        }
    }

    &__img {
        max-width: 150px;
        max-height: 60px;

        @media only screen and (max-width: 767px) {
            margin-bottom: 1rem;
        }
    }

    &__heading {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        margin: 0.1rem;
    }

    &__heading--alignment {
        text-align: end;
    }
}