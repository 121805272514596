@import "../../../assets/scss/mixins";

.footer-layout {
  @include flex(space-between, center);
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-layout__edm_link {
  margin-left: 2rem;
}

.footer-layout__reach_out {
  margin-right: 2rem;
}

.footer-layout__image {
  max-width: 12rem;
  max-height: 12rem;
}

@media only screen and (max-width: 900px) {
  .footer-layout {
    flex-direction: column;
    align-items: center;
    position: relative;
  }
}