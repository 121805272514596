@use "../../../assets/scss" as s;

.referenceValues {
    @include s.flexColumnHorAlignment(column, center);

    &__bar-fieldset {
        border-radius: 1rem;
        padding: 1rem 2rem 0.7rem 2rem;

        @media print {
            max-height: 80%;
            max-width: 100% !important;
            margin-bottom: 1rem;
            padding: 0;
        }

        @media only screen and (max-width: 451px) {
            padding: 0;
        }
    }

    &__bar-legend {
        margin-right: auto;
        font-weight: bold;
        font-size: 1.3rem;
        padding: 0 10px;

        @media only screen and (max-width: 992px) {
            font-size: 1.1rem;
        }
    }

    &__overallVulnerability {
        @include s.flexHorAlignment(flex-end);

        @media only screen and (max-width: 992px) {
            @include s.flexColumnHorAlignment(column, center);
            margin-right: -18px;
            margin-left: -18px;
        }

        @media print {
            @include s.flex(space-between, center);
            flex-direction: row;
        }
    }

    &__userResult {
        padding-bottom: 1rem;

        @media only screen and (max-width: 992px) {
            padding-left: 0;
            width: 70%;
        }
    }

    &__graphValues {
        width: 50%;
        height: 250px;
        font-size: 14px;
        font-weight: bold;
        color: black;
        margin : 0 auto;

        @media only screen and (max-width: 662px) {
            @include s.flexHorAlignment(center);
            margin-bottom: 100px;
        }
        @media print {
            margin-top: -100px;
        }
    }

    &__responsive-container{
        width: 75% !important;
        height: 100%  !important;

        @media only screen and (max-width: 451px) {
            width: 85% !important;
        }

        @media only screen and (min-width: 993px) and (max-width: 1280px){
            width: 91% !important;
        }

        @media only screen and (min-width: 1550px) and (max-width: 1845px){
            width: 101% !important;
        }

        @media only screen and (min-width: 1845px) and (max-width: 1922px){
            width: 81% !important;
        }
    }

    &__riskScaleFieldSet {
        border-radius: 10px;
        padding: 0 2rem 1rem 2rem;
        color: s.$darkGray;

        @media only screen and (max-width: 662px) {
            padding: 0.5rem;
        }

        @media print {
            padding: 0.5rem;
        }
    }

    &__riskScaleFooter > p {
        margin: 0;
        text-align: center;
        font-weight: 300;
        line-height: 1.56rem;
    }

    &__legend {
        font-style: normal;
        font-weight: 300;
        padding: 5px 15px;
    }

    &__howRUDoing {
        @include s.flexHorAlignment(center);
        margin-top: 1rem;
    }

    &__arrowContainer {
        display: flex;
        margin: 0.5rem
    }

    &__positionArrowOnTop {
        @include s.flexVerAlignment(flex-start);
    }

    &__positionArrowMiddle {
        @include s.flexVerAlignment(center);
    }

    &__positionArrowAtEnd {
        @include s.flexVerAlignment(flex-start);
        padding-top: 5.7rem;

        @media only screen and (max-width: 280px) {
            padding-top: 8.8rem;
        }

        @media only screen and (min-width: 281px) and (max-width: 360px) {
            padding-top: 7.5rem;
        }

        @media only screen and (max-width: 662px) {
            @include s.flexVerAlignment(flex-start);
        }
    }

    &__arrowContainer &__arrow {
        width: 0;
        height: 0;
        border-top: 23px solid transparent;
        border-bottom: 23px solid transparent;
        border-left: 23px solid s.$orange;
        margin-right: 2rem;

        @media print {
            margin-right: 1rem;
        }
    }

    &__scale {
        padding-right: 3rem;
        padding-left: 1.5rem;

        @media print {
            padding-right: 1rem;
            padding-left: 1rem;
        }

        @media only screen and (max-width: 600px) {
            padding-left: 0.7rem;
        }

        @media only screen and (max-width: 1550px) {
            padding-right: 0 !important;
        }
    }

    &__scale > * {
        margin-top: 1.5rem;
        font-size: 1.35rem;

        @media only screen and (max-width: 1150px) {
            font-size: 1rem !important;
        }
    }

    &__notActiveResult {
        color: lightgray;
        font-weight: bold;
    }

    &__activeResult {
        color: s.$orange;
    }

    &__great-activeResult {
        @media only screen and (min-width: 1280px) and (max-width: 1919px) {
            margin-top: 2rem !important;
        }

        @media only screen and (min-width: 1920px) and (max-width: 2500px) {
            margin-top: 2.2rem !important;
        }
    }

    &__reference-board-footer {
        font-size: 0.9rem;
        text-align: center;
        line-height: 1.25rem;
        color: s.$darkGray;
        margin-inline: auto;
        width: 85%;

        @media only screen and (max-width: 992px) {
            text-align: center;
        }

        @media print {
            margin-top: 0;
            font-size: 0.6rem;
        }
    }

    &__riskScaleFooter {
        margin-top: 1rem;

        @media only screen and (max-width: 992px) {
            padding: 1rem;
        }
    }

    &__riskScaleFooter > p {
        display: flex;
        flex-direction: column;
    }
}