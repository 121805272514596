@use '../../assets/scss' as s;

.main_page {
  @include s.flexDirection(column);

  &__header {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.3em;
    padding-left: 1rem;

    @media only screen and (max-width: 900px) {
      font-size: 1.4rem;
      text-align: center;
    }
  }

  .main_page__text {
    font-size: 1rem;
    margin-top: 2rem;
    padding-left: 1rem;

    @media only screen and (max-width: 900px) {
      font-size: 0.73rem;
      text-align: center;
      padding-left: 0;
    }
  }

  .main_page__auth {
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;

    @media only screen and (max-width: 900px) {
      justify-content: center;
      padding-left: 0;
    }

    .main_page__login-button {
      width: 20%;
      margin-top: 3rem;
      padding: 10px;

      @media only screen and (max-width: 950px) {
        padding-left: 0;
        width: 30%;
      }
    }

    .main_page__domain {
      @media only screen and (max-width: 900px) {
        font-size: 1rem;
      }
    }

    .main_page__domain:hover {
      color: s.$lightBlue;
    }
  }
}