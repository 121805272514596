.concepts {
  &__center-headers {
    text-align: center;
    font-size: 2rem;
  }

  &__header {
    font-size: 2rem;
  }

  &__block-example {
    background-color: #F9F9F7;
    margin-left: 5rem;
    padding: 1rem;
  }

  &__reference {
    margin: 3rem 0;
  }

  &__headers_green-color {
    color: #009933;
  }
}