@use "src/assets/scss" as s;

.urs {
  display: flex;
  flex-direction: column;
  flex: 0 0 75%;
  border: 1px solid white;
  border-radius: 1.25rem;
  background-color: #ffffff;
  margin: 0 0.4rem 2.5rem 0.4rem;
  padding: 1rem 2rem;
  box-shadow: 2px 5px 15px 5px #0000000d;

  @media only screen and (max-width: 2000px) {
    flex: 0 0 65%;
  }

  @media only screen and (max-width: 1200px) {
    flex: 0 0 62%;
  }

  @media only screen and (max-width: 450px) {
    padding: 16px 24px;
  }

  &__datepicker {
    max-width: 10rem;
  }

  .urs__desktop_search {
    display: flex;
    justify-content: flex-end;
    gap: 20px 80px;
    margin-bottom: 16px;

    @media only screen and (max-width: 1100px) {
      display: flex;
      flex-direction: column;
    }
  }

  .urs__mobile_search {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 120px;
    margin-bottom: 16px;
  }

  .urs__search {
    margin-right: auto;

    &-input {
      margin-right: 1.5rem;
      padding: 0.2rem 0.3rem;;
      border-radius: 0.4rem;
      border: 0.0625rem solid rgba(0, 0, 0, 0.6);
      font-style: italic;
      width: 230px;
      height: 2rem;

      &--focus:focus {
        background-color: s.$lightGray;
        outline: none;
      }
    }
  }

  .urs__search > input {
    margin-top: 0.5rem;
    padding: 0.2rem 0.3rem;
    border-radius: 0.4rem;
    border: 0.0625rem solid #bdc7cf;
    font-style: italic;
    width: 15rem;
  }

  .urs__content {
    margin-bottom: 2rem;

    .urs__content_title {
      font-size: 1rem;
      color: s.$darkGray;
      margin-bottom: 0.1rem;
      margin-top: 10px;
    }

  .urs__table_wrapper {
    overflow-x: auto;
  }

  .urs__table_wrapper::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .urs__table_wrapper::-webkit-scrollbar:horizontal {
    height: 7px;
  }

  .urs__table_wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.26);
    border-radius: 7px;
    border: 1px solid s.$lightGray;
  }

  .urs__table_wrapper::-webkit-scrollbar-track {
    border-radius: 7px;
    background-color: s.$lightGray;
  }

    .urs__table {
      width: 90%;

      .urs__content-member-link {
        text-decoration: none;
        color: #663399;
      }

      .urs__table_headers {

        .urs__table-headers-icon-wrapper {
          display: flex;
          flex-direction: row;
          width: 4.5rem;
        }

        .urs__table_headers > tr > td:last-child {
          width: 20rem;
        }

        .urs__table-headers-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .urs__table_headers > * {
        text-align: left;
        color: #909090;
        font-size: 16px;
      }

      .urs__table_headers > tr > td:not(:nth-last-child(-n+2)) {
        padding: 1rem 1rem 1rem 0;
        min-width: 12.5rem;
      }

      .urs__table_rows {
        border-bottom: 3px solid #F7F7F7;
        padding: 2rem;
      }

      .urs__table_rows > * {
        text-align: left;
        font-weight: 500;
        padding: 1rem 1rem 1rem 0;
        border-bottom: 3px solid #FCFCFC;
        font-size: 16px;
        word-break: break-word;
        max-width: 5rem;
      }
    }
  }
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {

  min-width: 149px !important;

  @media only screen and ( max-width: 600px){
    min-width: 0 !important;
  }
}

.MuiInputBase-inputSizeSmall {

  @media only screen and (max-width: 450px) {
    padding-left: 11px !important;
    padding-right: 11px !important;

  }
}