.create-edit-contact {
  width: 20rem;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 992px) {
    width: 18rem;
  }

  &__form {
    width: 15rem;
    font-family: 'Mulish', 'Quicksand', sans-serif;
  }

  &__header {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.25rem;
  }

  &__title-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__title-text {
    text-align: start;
    font-weight: bold;
    font-size: 1.1rem;
    width: 10rem;
  }

  &__delete-icon {
    color: #CC3366;

    &:hover {
      cursor: pointer;
    }
  }

  &__checkbox-label {
    align-self: center;
    margin-left: 0.1rem;
    font-size: 1rem;
  }

  &__primary-section {
    display: flex;
  }

  &__save-div {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row-reverse;
  }

  &__save-btn {
    background-color: #2DAAE2 !important;
    font-weight: 700 !important;
    border-radius: 8px !important;
    padding: 6px 25px !important;
  }

  &__save-btn.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;;
  }
}
