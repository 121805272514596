@use '../../../assets/scss' as s;

.yearly {
  border-radius: 1rem;
  padding: 2rem 2rem 1rem 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 992px) {
    padding: 1rem;
  }

  @media print {
    padding: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    page-break-after: always;
  }

  &__comparison-wrapper {
    margin-top: -1rem;

    @include s.flexHorAlignment(space-between);

    @media only screen and (max-width: 992px) {
      @include s.flexColumnHorAlignment(column, center);
      align-items: normal;
    }

    @media print {
      align-items: center;
    }
  }

  &__score-legend {
    margin-right: auto;
    font-weight: bold;
    font-size: 1.3rem;
    padding: 0 0.8rem;

    @media only screen and (max-width: 992px) {
      font-size: 1.1rem;
    }
  }

  &-comparison-scale {
    font-weight: bold;
    line-height: 0.8rem;
    font-size: 1.3rem;
    margin-left: 0.9rem;

    @media print {
      font-size: 1rem;
      margin-left: 0.6rem !important;
    }

    @media only screen and (max-width: 992px) {
      font-size: 1.1rem;
    }
  }

  &-comparison-scale :nth-child(1) {
    font-weight: normal;
    padding-bottom: 1rem;
  }

  &__circle-comparison {
    width: 25px;
    height: 20px;
    shape-outside: circle();
    clip-path: circle();
    margin-right: 5px;
  }

  &__circle-comparison--purple {
    background: s.$darkPurple;
  }

  &__circle-comparison--grey {
    background: s.$grey;
  }

  &__circle-comparison--lightblue {
    background: #6CE5E8;
  }

  &__comparison--item {
    display: flex;
    align-items: center;
    margin: 0;
    white-space: pre;
  }

  &__score-chart {
    width: 60%;
    height: 250px;
    font-weight: bolder;
    font-size: 1rem;
    color: s.$black;
    margin-right: 7.5rem;

    @media only screen and (max-width: 992px) {
      width: 100%;
      margin-right: 0;
      margin-top: 1.5rem;
    }

    @media print {
      margin-right: 0 !important;
      width: 72% !important;
    }
  }

  &__responsive-container {
    width: 100% !important;
    height: 100% !important;
  }

  &__line-chart {
      .recharts-surface {
        @media print {
          margin-top: 1.25rem;
          width: 90%;
        }
      }
  }
}

