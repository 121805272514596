@import url("https://fonts.googleapis.com/css?family=Mulish:400,700,300,600,300italic|Quicksand:400,300");

.app__admin-dashboard {
  font-family: 'Mulish', 'Quicksand', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #F5F5F5;
  background-image: url("../../assets/images/asset-4-1@1x.svg"),  url("../../assets/images/asset-4-2@1x.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: 0 95px, bottom 90px right;
  background-attachment: local, scroll;

  &-main {
    display: flex;
    margin: 0 auto;

    @media screen and (min-width: 1200px) {
      width: 1170px;
    }

    @media screen and (min-width: 1400px) {
        width: 1270px;
    }
    @media screen and (min-width: 1600px) {
        width: 1370px;

    }
    @media screen and (min-width: 1900px) {
        width: 1670px;
    }

    @media only screen and (max-width: 1200px) {
      width: 80%;
      flex-direction: column;
    }

    @media screen and (max-width: 600px) {
      width: 90%;
    }

    @media screen and (max-width: 480px) {
      //width: 85%;
      width: 94%;
    }
  }
}