@use "../../../assets/scss" as s;

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  @media (max-width: 1024px) {
    max-width: 100%;
    padding: 6px;
    margin: auto;
    text-align: center;
  }

  @media (max-width: 700px) {
    max-width: 90%;
    padding: 0 1.2rem;
    text-align: center;
  }

  @media (max-width: 1024px) {
    .modal-main > span {
      text-align: center;
      margin: 0 auto;
    }
  }

  @media (max-width: 1024px) {
    .modal-main > textarea, .member-modifier__main > textarea {
      width: 80%;
    }
  }

  &__container {
    font-size: 16px;
    font-style: normal !important;
    font-weight: 300 !important;
    background-color: s.$white;
    box-shadow: 2px 5px 15px 5px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 24px;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 9px;
    }

    ::-webkit-scrollbar:horizontal {
      height: 9px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      border: 1px solid s.$lightGray;
    }

    ::-webkit-scrollbar-track {
      border-radius: 6px;
      background-color:  s.$lightGray;
    }
  }

  &__sm {
    margin: auto;
    background-color: s.$white;
    //width: 40rem;
    max-width: 40rem;
    word-break: break-word;
    max-height: 40rem;
    text-align: left;
  }

  &__lg {
    margin: auto;
    background-color: s.$white;
    overflow: auto;
    max-width: 40rem;
    word-break: break-word;
    max-height: 30rem;
    font-size: larger;
    text-align: center;
  }

  &__xl {
    //width: 40rem;
    max-width: 40rem;
    height: 35rem;
  }

  &__xxl {
    //width: 47rem;
    //max-height: fit-content;
    max-width: 47rem;
    max-height: 45rem;
    min-width: 15rem;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-height: fit-content;
    padding-bottom: 2px;
  }

  &__header-btn {
    background-color: transparent;
    //color: s.$black;
    color: s.$darkGray;
    border: none;
    cursor: pointer;
    position: absolute;
    z-index: 1001;
  }

  &__body {
    flex: 50%;
    //display: flex;
    justify-content: center;
  }

  &__footer {
    flex: 5%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  @media (max-width: 1024px) {
    .modal__footer {
      flex: 5% 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      align-content: center;
    }
  }

  &__footer button {
    width: auto;
    height: 39px;
    margin: 10px 10px 5px 10px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(53, 48, 48, 0.1);
    color: white;
    cursor: pointer;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-size: 18px;
    //line-height: 23px;
    //align-items: stretch;
    text-align: center;
    padding: 8px 27px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: center;
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    .modal__footer button {
      font-size: 80%;
    }
  }

  &__btn-label {
    line-height: 1.65rem;
  }

  &__btn-cancel {
    background-color: s.$lightGray2 !important;
    color: s.$grey !important;
  }

  &__btn-submit {
    background-color: s.$lightBlue3 !important;
    color: s.$white !important;
  }
}
