@use '../../../assets/scss' as s;

.header-rep {
  @include s.flexHorAlignment(space-between);
  margin-top: 2.5rem;

  @media only screen and (max-width: 992px) {
    @include s.flexColumnHorAlignment(column, center);
  }

  @media print {
    margin-top: 0.5rem;
  }

  &__img {
    max-width: 200px;
    max-height: 100px;
  }

  &__address {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.56rem;
    margin-top: 2rem;

    @media only screen and (max-width: 992px) {
      font-size: 0.9rem;
      text-align: center;
    }
  }

  &__address_info {
    margin: 0
  }

  &__auth {
    @include s.flexHorAlignment(space-between);

    @media only screen and (max-width: 992px) {
      margin-top: 2rem;
    }
  }

  &__buttons {
    width: fit-content;
    height: 39px;
    border-radius: 8px !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1) !important;
    font-family: 'Mulish', sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 1.12rem !important;
    line-height: 1.44rem !important;

    @media only screen and (max-width: 600px) {
      width: 10.5rem !important;
      font-size: 1rem !important;
    }

    @media only screen and (min-width: 601px) and (max-width: 900px) {
      width: 9rem !important;
      font-size: 0.8rem !important;
    }

    @media only screen and (min-width: 901px) and (max-width: 1150px) {
        font-size: 1rem !important;
    }

    @media only screen and (min-width: 1151px) and (max-width: 1550px) {
        font-size: 1rem !important;
    }
  }

  &__dashboard_btn--margin {
    margin-right: 1rem !important;

    @media only screen and (max-width: 600px) {
      margin-right: 0 !important;
    }
  }

  &__buttons--margin {
    margin: 20px 0 5px 10px !important;
  }

  &__tell_me_more_btn {
    width: fit-content;
    height: 39px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    font-family: 'Mulish', sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.12rem !important;
    line-height: 1.44rem;

    @media only screen and (max-width: 600px) {
      font-size: 0.7rem !important;
    }

    @media only screen and (min-width: 601px) and (max-width: 900px) {
      font-size: 0.8rem !important;
    }

    @media only screen and (min-width: 901px) and (max-width: 1550px) {
      font-size: 1rem !important;
    }
  }

  &__tell_me_more_div {
    display: flex;
    justify-content: center;
  }

  &__profile {
    text-decoration: none;
    color: s.$black;
  }
}
