@use '../MemberManagement/MemberManagement';

.campaign-management {
    @extend .member-management;
    padding: 2rem 2rem;

    &__fields {
        display: flex;
        flex-wrap: wrap;
        gap: 12px !important;
    }
}