@use '../../../../assets/scss' as s;

.login-table {
  &__button:hover {
    background-color: s.$lightGray;
    cursor: pointer;
  }

  &__modal_title {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.25rem;
  }

  &__user-info {
    @include s.modal-headers(7rem);
    display: flex;
  }
}

