@use "../../Dashboard/DashboardNavigation/DashboardNavigation";

.app__ss {
    display: flex;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    &-settings {
        flex: 150px 0 0;
    }

    &-s-paths {
        @extend .nav-dash__paths;
        margin-top: 0;
        padding: 2rem 3rem 2rem 2rem;
        //min-width: 9.375rem;

        @media only screen and (max-width: 992px) {
            margin-right: 0;
        }
    }

    &-account-icon {
        cursor: pointer;
        color: #555;
    }

    &-settings-management {
        @extend .nav-dash__paths;
        min-width: 800px;
        margin: 0 .4rem 2.5rem;

        @media only screen and (max-width: 992px) {
            min-width: 280px;
            margin: unset;
        }
    }

    @media only screen and (max-width: 992px) {
        flex-direction: column;
    }
}