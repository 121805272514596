@use "./src/assets/scss" as s;

.navigation-tabs {
  &__tab-group {
    display: flex;
    align-items: center;
  }

  &__tab {
    background-color: rgba(255, 255, 255, 0.5);
    color: s.$darkGray;
    height: 1.2rem;
    border-radius: 0.7rem 0.7rem 0 0;
    width: 10rem;
    padding: 0.7rem 1rem 0.7rem 1rem;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    vertical-align: middle;
    font-size: 1rem;

    @media only screen and (max-width: 1399px) {
      font-size: 0.8rem;
      width: 5rem;
    }

    @media only screen and (max-width: 620px) {
      //margin-left: 1.5rem;
      font-size: 0.8rem;
      width: 4rem;
    }

    &--active {
      background-color: s.$white !important;
    }

    &--margin {
      margin-left: 2.5rem;

      @media only screen and (max-width: 620px) {
        margin-left: 30px;
        padding: 0.5rem 0.5rem 1.2rem 0.5rem;
      }
    }
  }
}
