@use '../../../../assets/scss' as s;

.import-csv-wrapper {
    max-height: 35rem;
    overflow-y: auto;
}

.csv-modal-form-control-label {
    text-align: left;

    @media only screen and (max-width: 650px) {
        margin-bottom: 24px;
    }
}

.member-modal {
    position: relative;
    margin-top: 10%;
    margin-left: 37%;
    background-color: s.$white;
    border-radius: 0.5rem;
    max-width: fit-content;
    height: auto;
    text-align: center;

    &__info {
        margin-top: 1rem;
        margin-left: 1rem;
        margin-bottom: 0.2rem;
    }

    &__icon {
        font-size: 3rem !important;
        color: s.$darkBlue;
        stroke: s.$white;
        stroke-width: 1;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        text-align: justify;
        padding: 0;
        width: fit-content;
        height: fit-content;
    }

    &__instr {
        float: left;
        width: 390px;
    }

    &__button {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        margin-left: 0.5rem;
        text-align: center;
        padding: 0;
        width: 80px;
    }

    &__upload-buttons {
        padding: 6px 1px;
        cursor: pointer;
        color: s.$white;
        border-radius: 4px;
        font-weight: bold;
        text-align: center;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        align-items: center;
        font-size: 12px;
        background-color: s.$darkBlue;
        margin: auto;
        border: 2px solid s.$darkBlue;
        width: 70px;
    }

    &__upload-buttons:hover {
        background-color: white;
        color: s.$darkBlue;
        border: 2px solid s.$darkBlue;
    }

    &__mobile-upload-margin {
        @media only screen and (max-width: 700px) {
            margin-bottom: 32px;
        }
    }
}