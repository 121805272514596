@mixin flex($horPosition, $verPosition ) {
  display: flex;
  justify-content: $horPosition;
  align-items: $verPosition;
}

@mixin flexHorAlignment($horPosition) {
  display: flex;
  justify-content: $horPosition;
}

@mixin flexVerAlignment($verPosition) {
  display: flex;
  align-items: $verPosition;
}

@mixin flexDirection($direction) {
  display: flex;
  flex-direction: $direction;
}

@mixin flexColumnHorAlignment($direction, $horPosition) {
  display: flex;
  flex-direction: $direction;
  align-items: $horPosition;
}

@mixin flexColumnVerAlignment($direction, $verPosition) {
  display: flex;
  flex-direction: $direction;
  justify-content: $verPosition;
}

@mixin member-management {
  @include flexDirection(column);
  flex: 0 0 75%;
  border: 1px solid white;
  border-radius: 1.25rem;
  background-color: white;
  margin: 2.5rem 0.4rem;
  padding: 1rem 2rem;

  @media only screen and (max-width: 2000px) {
    flex: 0 0 65%;
  }

  @media only screen and (max-width: 1200px) {
    flex: 0 0 63%;
  }
}

@mixin modal-headers($minWidth) {
  min-width: $minWidth;
  font-style: italic;
  font-size: 14px;
}