.edit-assets {

  &__header {
    margin-bottom: 2rem;
    font-size: 1.25rem;
    width: 40rem;

    @media only screen and (max-width: 750px){
      width: unset;
    }

    @media only screen and (max-width: 450px) {
      padding: 0 1.4rem 1rem 0.4rem;
    }
  }

  &__domains-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2rem;
    text-align: start;
    font-family: 'Mulish', serif;

    @media only screen and (max-width: 750px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__domain-entry {
    width: 50%;
    display: flex;
    margin-bottom: 2rem;

    @media only screen and (max-width: 750px) {
      width: 100%;
    }
  }

  &__domain-data {
    width: 80%;
  }

  &__domain-name {
    font-weight: 700;
    font-size: 1.18rem;
    line-height: 1.37rem;
    color: #333333;
  }

  &__score-label {
    font-weight: 300;
    font-style: italic;
    font-size: 0.9rem;
    line-height: 1rem;
    margin: 0.5rem 0;
  }

  &__score-value {
    color: #2FA84F;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.9rem;
  }

  &__domain-delete {
    color: #CC3366;

    &:hover {
      cursor: pointer;
    }
  }

  &__add-form {
    display: flex;
    height: fit-content;
  }

  &__add-button {
    height: fit-content;
  }

  &__add-button.Mui-disabled > svg {
    fill: rgba(0, 0, 0, 0.12) !important;
    height: fit-content;
  }

  &__add-icon {
    fill: #2FA84F !important;
  }

  &__cancel-button {
    height: fit-content;
  }

  &__cancel-icon {
    fill: #CC3366 !important;
  }
}
