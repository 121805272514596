.app__footer {
  padding: 1rem 3rem;
  background-color: #323595;
  color: #ffffff;
  font-size: 18px;
  font-weight: normal;
  margin-top: auto;
}

.app__footer-terms {
  font-size: 14px;
}