$white: #FFFFFF;
$grey: #818A91;
$lightBlue: #ABE5FF;
$pink: #EBB0E7;
$pink2: #EA3D2F33;
$alertRed: #EA3D2F;
$lightGray: #F7F7F7;
$darkGray: #555555;
$lightBlue2: #ABE5FF4D;
$orange: #FF7052;
$lightRed: #EA3D2F33;
$lightGray2: #F4F4F4;
$darkGray2: #333333;
$lightBlue3: #2DAAE2;
$mauve: #CC3366;
$alertYellow: #FFC90B;
$lightGray3: #BDC7CF;
$darkGray3: #353030;
$lightPurple: #7B61FF;
$lightOrange: #F3AA18;
$alertGreen: #58D14E;
$black: #000000;
$darkPurple: #323595;
$lightYellow: #FFE7AB;
$darkBlue: #1976d2;
$green: #2FA84F;
$darkPurple3: #663399;
$greyMiddle: #808080;
