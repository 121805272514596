@use '../../assets/scss/variables' as s;

.modal_viq_bg {
  background-color: s.$white;
  border-radius: 20px;
  box-shadow: 2px 5px 15px 5px rgba(0, 0, 0, 0.05);
}

.modal_viq_body {
  max-height: 36rem;
  overflow-y: auto;

  @media only screen and (max-width: 1200px) {
    max-height: 30rem;
  }
}