.app__reports-wrapper {
  max-width: 45%;
  margin-inline: auto;
  font-size: 1.2rem;

  @media only screen and (max-width: 1550px) {
      max-width: 60%;
      font-size: 1rem !important;
  }

  @media only screen and (max-width: 1150px) {
      max-width: 70%;
      font-size: 1rem !important;
  }

  @media only screen and (max-width: 900px) {
      max-width: 75%;
      font-size: 1rem !important;
  }

  @media only screen and (max-width: 600px) {
      max-width: 90%;
      font-size: 0.9rem !important;
  }

  @media print {
      max-width: 90%;
      margin-inline: auto;
      font-size: 0.95rem !important;
  }
}

.app__reports-modal {
  padding: 0 2rem 1rem 2rem;

  h3 {
    margin: 0 !important;
  }

  p {
    margin-bottom: 0 !important;
  }
}