@use "../MemberManagement/MemberManagement";
@use "../TicketTracking/TicketTracking";
@use "src/assets/scss" as s;

.engagement {
  @extend .member-management;
    padding: 2rem 2rem;

  &__linechart-container {
    max-width: 730px;
    min-width: 730px;
    width: 80%;
    height: 100%;

    @media only screen and (max-width: 1200px) {
      max-width: 730px;
      min-width: unset;
      width: 90%;
      height: 100%;
    }
  }

  &__title-props {
        font-size: 1.1rem;
        color: s.$darkGray;
        //margin-bottom: 0;
        margin: 12px 12px 0 0
    }

    &__subtitle-props {
        font-size: 14px;
        color: s.$darkGray;
        margin-top: 0;
    }

  &__title {
    display: flex;
    //flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 12px;
    margin-bottom: 45px;
  }

  &__description {
    font-weight: 300;
    min-height: auto;
    padding: 0;
    margin: 12px 0 0 0;

    &__margin {
      margin-right: 0.3rem;
      white-space: nowrap;
    }
  }

  &__link {
    text-decoration: underline;
    color: cornflowerblue;
    //margin-left: 0.3rem;

    &--hover {
      cursor: pointer;
      white-space: nowrap;
    }
  }

    &__ticket {
        @extend .ticket-tracking__ticket;
        border: 1px s.$lightOrange solid;
    }

  &--body-left {
    @extend .ticket-tracking--body-left;
  }

  &__domain {
    @extend .ticket-tracking__domain;
  }

  &__link {
    @extend .ticket-tracking__link;
  }

  &__companyName {
    @extend .ticket-tracking__companyName;
  }

  &__domain-name {
    @extend .ticket-tracking__domain-name;
  }

  &__description {
    @extend .ticket-tracking__description;
  }

  &__eng-ticket-description-modal {
    padding: 0 8px 8px 8px;
    overflow: auto !important;
    max-width: 40rem;
    max-height: 35rem;
    white-space: pre-wrap;
    font-size: 18px;
    line-height: 23px;
  }

  &__contacts {
    @extend .ticket-tracking__contacts;
  }

  &__button {
    @extend .ticket-tracking__button;
  }

  &__button {
    @extend .ticket-tracking__button;

  }

  &--button-margin {
    @extend .ticket-tracking--button-margin;
  }

  &__score {
    @extend .ticket-tracking__score;
  }

  &__src {
    @extend .ticket-tracking__src;
  }

  &__score-text {
    @extend .ticket-tracking__score-text;
  }

  &__score-number {
    @extend .ticket-tracking__score-number;
  }

  &__score-button {
    @extend .ticket-tracking__score-button;
  }

  &__no-tickets {
    @extend .ticket-tracking__no-tickets;
  }
}


.app__dashboard-engagement-ticket-section-no-tickets {
  font-weight: 400;
}
