.custom-tooltip {
  background: white;
  border-style: solid;
  border-width: thin;

  p {
    padding-left: 5px;
    padding-right: 5px;
  }
}
