.dash-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 2px 5px 15px 5px #0000000d;

    &__img-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 2.5rem;

        @media only screen and (max-width: 600px) {
            margin-left: 1rem;
        }
    }

    &__img {
        max-width: 6rem;
    }

    &__title {
        margin-left: 1.5rem;
        font-size: 20px !important;
        color: #2e3192;
        font-weight: normal;

        @media only screen and (max-width: 600px) {
            font-size: 18px !important;
        }

        @media only screen and (max-width: 480px) {
            font-size: 15px !important;
        }
    }

    &__title__dashboard {
        margin-bottom: 0;
        //margin-top: 18px;
        font-size: 20px !important;
        color: #2e3192;
        font-weight: normal;

        @media only screen and (max-width: 600px) {
            font-size: 18px !important;
            margin-top: 28px;
        }

        @media only screen and (max-width: 480px) {
            font-size: 18px !important;
            padding-right: 3px;
            margin-top: 29px;
        }
    }

    &__title__path {
        //margin-bottom: 18px;
        margin-top: 0;
        white-space: nowrap !important;
        font-size: 20px !important;
        color: #2e3192;
        font-weight: normal;

        @media only screen and (max-width: 600px) {
            font-size: 18px !important;
            margin-bottom: 8px;
        }

        @media only screen and (max-width: 480px) {
            font-size: 15px !important;
            padding-right: 3px;
            margin-bottom: 7px;
        }
    }

    &__logout {
        margin-right: 2rem;

        @media only screen and (max-width: 1200px) {
            margin-right: 20px !important;
        }

        @media only screen and (max-width: 600px) {
            margin-right: 14px !important;
        }
    }

    &__custom-select {

        &:before {
            border: none !important;
        }

        &:focus {
            border: none !important;
        }
    }
}
