.yellow-loader {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background: repeating-linear-gradient(135deg, #FFC90B 0 10px, #F3AA18 0 20px) 0/0% no-repeat,
  repeating-linear-gradient(135deg, #ddd 0 10px, #eee 0 20px) 0/100%;
  animation: p3 2s infinite;

  @keyframes p3 {
    100% {
      background-size: 100%
    }
  }
}