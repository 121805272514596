@use 'variables' as s;

.quicksand {
    font-family: 'Quicksand', serif;
}

.mulish {
    font-family: 'Mulish', sans-serif !important;
}

.font-lato {
    font-family: 'Lato', sans-serif;
}

.text-13 {
    font-size: 13px;
}

.text-light-orange {
    color: s.$lightOrange;
}

.text-orange {
    color: s.$orange;
}

.text-green {
    color: s.$green;
}

.text-alert-red {
    color: s.$alertRed;
}

.text-dark-grey {
    color: s.$darkGray;
}

.text-dark-purple {
    color: s.$darkPurple !important;
}

.text-light-blue-3 {
    color: s.$lightBlue3;
}

.text-grey {
    color: s.$grey;
}

.text-dark-grey-2 {
    color: s.$darkGray3;
}

.bg-light-yellow {
    background: s.$lightYellow;
}

.bg-light-grey {
    background: s.$lightGray;
}

.bg-light-grey2 {
    background: s.$lightGray2;
}

.bg-pink2 {
    background: s.$pink2;
}

.bg-light-blue1 {
    background: rgba(171, 229, 255, 0.3);
}

.bg-light-blue-3 {
    background: s.$lightBlue3 !important;
}

.bg-orange {
    background: s.$orange !important;
}

.bg-green {
    background: s.$green;
}

.bg-alert-yellow {
    background: s.$alertYellow;
}

.bg-alert-red {
    background: s.$alertRed;;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}