@import "../../../assets/scss/index";

.sg-nav {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  background-color: #FAFAFA;
  height: 100%;

  &__logo {
    max-width: 12rem;
    max-height: 5rem;
  }

  &__getting_started {
    display: flex;
    flex-direction: column;

    .sg-nav__link {
      text-decoration: none;
      color: black;
    }

    .sg-nav__link_hover:hover {
      color: #2DAAE2;
    }
  }
}