.dashboard-wrapper {
  margin-top: 2.5rem;
  width: inherit;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 24px !important;
  }
}
