@use '../../../assets/scss' as s;

.non-tracked-ips {
  &__convert-btn {
    background-color: s.$lightBlue3 !important;
  }

  &__convert-btn-disabled {
    color: #00000042 !important;
    background-color: #0000001f !important;
    box-shadow: none !important;
  }
}