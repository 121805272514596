@use '../../../../../assets/scss/index' as s;

.dropdown-actions {
  display: flex;
  flex-direction: column;
  font-family: 'Mulish', sans-serif !important;

  &__title {
    font-weight: 300;
    font-size: 18px;
    color: #555555;
    padding: 0 0.375rem 0.5rem 0.375rem;
  }

  &__form-control {
    width: 33.333%;
    padding: 0.375rem !important;
    margin: 0 0.5rem 0 0 !important;
  }

  &__form-control-label {
    font-size: 14px !important;
  }

  &__form-control-select {
    padding: 0;
    margin: 0;
    border-radius: 8px;
    height: 2.5rem;
  }

  &__buttons {
    font-family: 'Mulish', sans-serif !important;
    font-weight: 700 !important;
    color: white !important;
    border-radius: 8px !important;
    margin: 0.375rem !important;
    height: 2.438rem
  }

  &__go-button {
    background-color: s.$lightBlue3 !important;
  }

  .Mui-disabled {
    background-color: #e0e0e0 !important;
  }

  &__add-tenant-button {
    background-color: #F3AA18 !important;
  }

  &__selected-tenants-p {
    font-weight: 400 !important;
    font-size: 14px;
    color: #555555;
  }
}