@use '../../../../assets/scss' as s;

.member-info {
    @include s.flexDirection(column);
    width: 100%;
    flex: auto 1 0;
    margin-top: 0.1rem;
    margin-left: 0.1rem;
    background-color: s.$lightGray2;
    //padding: 1.5rem 1rem;
    border-bottom-right-radius: 1.25rem;

    @media only screen and (max-width: 992px) {
        border-radius: 1.25rem;
        width: 100%;
    }

    &__padding_wrapper {
        padding: 1.5rem 1rem;
    }

    &__button_group_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px 24px;
        padding-right: 12px;
    }

    &__h4 {
        margin: 0 0 0.1rem 0;

        @media only screen and (max-width: 720px) {
            flex-wrap: wrap;
            gap:12px;
        }
    }

    &__scan-data-btn {
        background-color: inherit !important;
    }

    &__tech-report-btn {
        background-color: inherit !important;

        @media only screen and (max-width: 720px) {
            padding-left: 0 !important;
        }
    }

    &__p {
        overflow: hidden;
        margin: 0.5rem 0 1rem 0;

        @media only screen and (max-width: 1600px) {
            margin: 0.4rem 0 0.4rem 0;
        }
    }

    &__primary {
        display: block;
        color: s.$lightBlue3 !important;
    }

    &__card-content {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        word-break: break-word;

        &:hover {
            cursor: pointer;
        }
    }

    &--pointer {
        cursor: pointer;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        word-break: break-word;

        &:hover {
            text-decoration: underline;
        }
    }

    &--pointer {
        cursor: pointer;
    }

    &__header {
        @include s.flexHorAlignment(space-between);
        margin-bottom: 0.5rem;
    }

    &__score-board {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 8px 64px;
        margin-bottom: 20px;
        margin-top: 12px;
    }

    &__score {
        margin-bottom: 0;
    }

    &__score-area {
        display: flex;
        align-items: center;
    }

    &__score-title {
        font-size: 14px;
        margin-bottom: 0.3rem;
        margin-top: 0.2rem;
        margin-right: 0.3rem;
        white-space: nowrap;
    }

    &__score-icon {
        cursor: pointer;
    }

    &__rescan-icon {
        font-size: 1.7rem;
        color: rgba(50, 53, 149, 1);
        margin-bottom: 12px;
        margin-top: 0.1rem;
        margin-left: 0.5rem;
        cursor: pointer;
    }

    &__score-main {
        font-weight: bold;
        font-size: 24px;
        margin-top: 0.1rem;
        margin-bottom: 12px;
    }

    &__score-value {
        color: s.$green;
    }

    &__score--pending {
        color: s.$lightOrange;
    }

    &__score--failure {
        color: s.$alertRed;
    }

    &__card-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
    }

    &__contacts-cards {
        display: flex;
        margin-right: 15px;
    }

    &__contacts-card {
        margin-top: 1rem;
        background: white;
        border-radius: 8px;
        padding: 1rem;
    }

    &__contact-header {
        display: flex;
        justify-content: space-between;
    }

    &__contacts-name {
        margin: 0 0 1rem 0;
    }

    &__contacts-headers {
        display: flex;
        flex-direction: column;
    }

    &__card-row {
        display: flex;
        align-items: center;
    }

    &__row-header {
        @include s.modal-headers(5rem);
        color: s.$darkGray2;
    }

    &__contacts-titles {
        margin-right: 10px;
        font-style: italic;
        font-size: 14px;
        color: s.$darkGray2;
    }

    &__contacts-content {
        font-size: 14.5px;
        font-weight: 500;
        color: s.$darkGray3;
    }

    &__details {
        @include s.flexColumnVerAlignment(column, space-between);
        margin-top: 2rem;
    }

    &__details-headers {
        display: flex;

        @media only screen and (max-width: 740px) {
            margin-top: 12px;
        }
    }

    &__add-contact-btn {
        display: flex;
        border: none;
        padding: 6px 10px;
        cursor: pointer;
        color: s.$darkPurple;
        border-radius: 4px;
        font-weight: 530;
        text-align: center;
        align-items: center;
        font-size: 16px;
        background-color: inherit !important;
        width: inherit !important;
    }

    &__add-contact-btn:hover {
        text-decoration: underline;
    }

    &__details-button {
        font-size: 1.2rem !important;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.3);
        margin-left: 10px;
    }

    &__details-content {
        @include s.flexHorAlignment(flex-start);

        @media only screen and (max-width: 840px) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__content-section-middle {
        margin: 0 4rem 0 0;
    }

    &__content-section {
        margin: 0 2rem 0 0;
    }

    &__content-headers {
        font-family: 'Mulish', 'Helvetica', 'sans-serif';
        font-style: italic;
        font-size: 14px;
        color: s.$darkGray2;
    }

    &__address {
        margin: 0.1rem 0;
    }

    &__assets {
        margin: 1rem 0;
    }

    &__content-wrapper {
        margin: 0.5rem 0;
    }

    &__assets-content {
        @include s.flexHorAlignment(flex-start);
        margin-top: 1rem;

        @media only screen and (max-width: 640px) {
            display: flex;
            flex-wrap: wrap;
            max-width: fit-content;
        }
    }

    &__assets-score {
        flex: 1 1 150px;
        margin-bottom: 8px;

        @media only screen and (max-width: 640px) {
            flex: unset;
        }
    }

    &__table-icons {
        vertical-align: middle;
        margin: 0 0.2rem 0 0;
    }
}

.member-info button {
    border: 0;
    border-radius: 0.4rem;
    background-color: s.$white;
    padding: 0 0.8rem;
    width: 100px;
    cursor: pointer;
}

.scan-data-wrapper {
    max-height: 40rem;
    word-break: break-word;

    @media only screen and (max-width: 992px) {
        max-height: 35rem;
    }
}

.scan-data-export-btn {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 650px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.css-1ex1afd-MuiTableCell-root {
    @media only screen and (max-width: 650px) {
       padding: 10px 6px 10px 6px !important;
    }
}
.scan-data-table-row-width {
    min-width: 100px;
    @media only screen and (max-width: 650px) {
        min-width: 88px;
    }
}

.scan-data-table-cell-align {
    display: block;
    height: 31px;
}