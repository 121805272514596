.modal-window {
  margin: auto;
  background-color: white;
  border: 2px solid #fff;
  max-width: fit-content;
  border-radius: 0.5rem;
  padding: 0 2rem 1rem 2rem;
}

.modal-main {
  //font-size: larger;
  text-align: center;
}

.modal-main > textarea {
  margin-top: 1rem;
  padding: 1rem;
  width: 32rem;
  height: 5rem;
  resize: none;
  font-family: 'Mulish', sans-serif;
  letter-spacing: 1px;
}

.modal-main > span {
  font-weight: 700;
  margin: 0 1rem;
  text-align: center;
}

.modal-main > Button {
  margin-top: 0.5rem;
}

.modal-message {
  padding: 0 2rem 1rem 2rem;
}

.modal-message > h3 {
  margin: 0 !important;
}

.modal-message > p {
  margin-bottom: 0 !important;
}



