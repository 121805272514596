@use "../MemberManagement/MemberManagement";
@use '../../../assets/scss' as s;

.campaign-stats {
  @extend .member-management;
  padding: 2rem 2rem;

  &__linechart-container {
    max-width: 730px;
    min-width: 730px;
    width: 80%;
    height: 100%;

    @media only screen and (max-width: 1200px) {
      max-width: 730px;
      min-width: unset;
      width: 90%;
      height: 100%;
    }
  }

  &__pdf_reports_wrapper {

    @media only screen and (max-width: 992px) {
      min-width: 100%;
      min-height: 180px;
      margin-bottom: 18px;

      ::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      ::-webkit-scrollbar:vertical {
       display: none;
      }

      ::-webkit-scrollbar:horizontal {
        height: 7px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.26);
        border-radius: 7px;
        border: 1px solid s.$lightGray;
      }

      ::-webkit-scrollbar-track {
        border-radius: 7px;
        background-color: s.$lightGray;
      }
    }
  }

  &__list-stats {
    display: flex;
    flex-direction: row;
    min-height: 3rem;
    margin: 1rem 0;

    @media only screen and (max-width: 992px) {
      min-height: 2rem;
      margin: 8px 0;
    }
  }

  &__list-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    @media only screen and (max-width: 992px) {
      min-height: 2rem;
      margin-bottom: 20px;
      font-size: 15px;
    }
  }

  &__stats-entry {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    justify-content: space-around;
    font-weight: bold;
    color: #555555;
    white-space: nowrap;

    @media only screen and (max-width: 480px) {
      margin-right: 1.5rem;
    }
  }

  .campaign-stats__stats-entry--start {
    @extend .campaign-stats__stats-entry;
    margin-left: 3rem;
  }

  &__stats-value {
    color: rgb(47, 168, 79);
  }

  &__chart-header {
    color: #555555;
  }
}
