@use '../../assets/scss/variables' as s;

.rec-accordion {
    margin-bottom: 1rem;
    border: 4px solid s.$lightGray;
    border-radius: 20px !important;
    box-shadow: none !important;

    &::before {
        content: '' !important;
        position: initial !important;
    }
}

.modal_recommendations_bg {
    background-color: s.$white;
    border-radius: 20px;
    box-shadow: 2px 5px 15px 5px rgba(0, 0, 0, 0.05);
}

.modal_recommendations_body {
    max-height: 35rem;
    overflow-y: auto;

    @media only screen and (max-width: 1200px) {
        max-height: 30rem;
    }
}