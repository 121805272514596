@use "../../../assets/scss" as s;

.suggestions {
    margin-top: 2rem;

    @media print {
        margin-top: 0;
    }

    &__main-header {
        text-align: center;
        font-style: normal;
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 1.56rem;

        @media only screen and (max-width: 992px) {
            text-align: center;
            font-size: 1.1rem;
        }

        @media print {
            margin: 0.5rem;
            font-size: 1rem;
        }
    }

    &__sections {
        @include s.flexHorAlignment(space-evenly);
        margin-top: 1rem;

        @media only screen and (max-width: 992px) {
            @include s.flexColumnHorAlignment(column, center);
        }

        @media only screen and (max-width: 992px) {
            margin-bottom: 10px;
        }

        @media print {
            padding: 0 1.5rem 0 1.5rem;
        }
    }

    &__section-wrapper {
        min-height: 315px;
        line-height: 1.7rem;

        @media only screen and (max-width: 1920px) {
            min-height: 420px !important;
        }

        @media only screen and (max-width: 1680px) {
            min-height: 305px;
        }

        @media only screen and (max-width: 1440px) {
            min-height: 300px;
        }

        @media only screen and (max-width: 1336px) {
            min-height: 295px;
        }

        @media only screen and (max-width: 1024px) {
            min-height: 293px;
        }

        @media print {
            line-height: 1.1rem;
            min-height: 313px;
        }
    }

    &__section {
        border-radius: 1rem;
        width: 55%;
        padding: 0 1.5rem 1.5rem 1.5rem;
        margin-right: 2px;

        @media print {
            padding: 0 1.5rem 0 1.5rem;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 800;
        font-size: 1.37rem;
        line-height: 1.75rem;
        margin-top: 1rem;

        @media print {
            font-size: 0.87rem;
            line-height: 1.25rem;
        }

        @media only screen and (max-width: 992px) {
            font-size: 1.1rem;
        }
    }

    &__header-margin {
        margin-bottom: 0;
    }

    &__subheaders {
        font-style: normal;
        font-weight: 600;
        font-size: 0.94rem;
        line-height: 1.18rem;
        position: relative;
        margin: 0;
        padding-bottom: 0.8rem;
    }

    &__content {
        font-style: normal;
        font-weight: 400;
        font-size: 1.37rem;
        line-height: 1.75rem;

        @media print {
            font-size: 0.87rem;
            line-height: 1.25rem;
        }

        @media only screen and (max-width: 992px) {
            font-size: 1.1rem;
        }
    }

    &__subheaders--width::before {
        border-bottom: 4px;
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 40%;
    }

    &__score-points {
        font-style: normal;
        font-weight: 900;
        font-size: 1rem;
        line-height: 1.25rem;
        text-align: center;
    }

    &__qr-or-button--center {
        text-align: center;
    }

    &__points {
        font-size: 2.5rem;
        vertical-align: top;
        padding: 0 0.5rem;
    }

    &__points-text {
        vertical-align: bottom;
    }

    &__qr-code {
        @media print {
            margin-top: 0;
        }
    }

    &__qr-direction {
        @media print {
            margin-bottom: 0.5rem;
        }
    }

}