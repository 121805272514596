@use '../../../../assets/scss' as s;

.scroll-member {
    display: flex;
    min-width: 223px;
    min-height: 500px;
    max-height: 100%;

    @media only screen and (max-width: 992px) {
        min-width: 100%;
        min-height: 200px;
        max-height: 300px;
        margin-bottom: 18px;

        ::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        ::-webkit-scrollbar:vertical {
            width: 5px;
        }

        ::-webkit-scrollbar:horizontal {
            height: 5px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.26);
            border-radius: 7px;
            border: 1px solid s.$lightGray;
        }

        ::-webkit-scrollbar-track {
            border-radius: 7px;
            background-color:  s.$lightGray;
        }
    }

    &__button {
        color: s.$darkGray;
        font-size: 0.9rem;
        background-color: s.$white;
        margin-bottom: 5px;
        width: 90%;
        height: 35px;
        text-overflow: ellipsis;
        border: none;
        text-align: left;
        clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        border-radius: 0.4rem;
        cursor: pointer;

        &:focus-visible {
            outline: none;
        }
    }

    &__button:hover {
        cursor: pointer;
        background-color: s.$lightGray2;
        overflow: visible;
    }

    &__button--clicked {
        background-color: s.$lightGray2;
        overflow: visible;
        color: s.$darkGray;
        font-size: 0.9rem;
        margin-bottom: 5px;
        width: 100%;
        height: 35px;
        text-overflow: ellipsis;
        border: none;
        text-align: left;
        clear: both;
        display: inline-block;
        white-space: nowrap;
        border-radius: 0.4rem;
        cursor: pointer;

        &:focus-visible {
            outline: none;
        }
    }
}