@use "../Dashboard/MemberManagement/MemberManagement";
@use "../Dashboard/styles" as dashboardStyle;
@use "../Reports/styles" as reportsStyle;

.app__self-serve {
    @extend .app__admin-dashboard;

    &-main {
        @extend .app__admin-dashboard-main;
        justify-content: center;

        @media only screen and (max-width: 1200px) {
            align-items: center;
        }
    }

    &-card {
        @extend .member-management;
        margin-top: 2rem;
        padding: 0;
    }

    &__report {
		@extend .app__reports-wrapper;
        max-width: 65%;

        @media only screen and (max-width: 1550px) {
            max-width: 80%;
        }

        @media only screen and (max-width: 1150px) {
            max-width: 85%;
        }

        @media only screen and (max-width: 900px) {
            max-width: 90%;
        }

        @media only screen and (max-width: 600px) {
            max-width: 95%;
        }
    }
}

.text-field-root {
    background-color: white !important;
}