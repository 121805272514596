.urs__quarters {
  border: 1.5px none green;
  border-bottom-style: solid;

  @media only screen and (max-width: 992px) {
    border: none;
  }

    .urs__quarters-legend {
    position: absolute;
    margin-top: 9.35rem;
    //margin-left: 20%;
    width: 8.5px;
    transform: translate(-50%, 50%);
    background: white;
    border-bottom: 0;
  }

  .urs__quarters-body {
    display: flex;
    justify-content: space-around;

    .urs__quarter-title {
      margin-left: 1rem;
    }

    .urs__quarter {
      display: flex;
      justify-content: space-around;

      .urs__status_wrapper {
        text-align: center;

        .urs__pdf_icon {
          border: 1.5px solid;
          border-radius: 7px;
          padding: 0.6rem;
        }

        .urs__status {
          //margin-top: 3px;
          margin: 3px 3px 16px 3px;
          text-align: center;
          font-family: Mulish, serif;
          color: #555555
        }

      }
    }

    .urs__scroller {
      display: none;

      @media only screen and (max-width: 1900px) {
        display: block;
      }
    }

    .urs__scroller--left_border {
      text-align: center;
    }

    .urs__scroller--right_border {
      text-align: center;
      margin-right: 1rem;
    }

    .urs__quarter-divider {
      border-left: 1.5px dotted grey;
      margin-bottom: 2.5rem;
    }
  }

  &-body > * {
    flex-grow: 1;
  }
}
