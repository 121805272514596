.ips-table {
  &__head {
    font-family: Mulish, Quicksand, serif !important;
    font-style: italic;
  }

  &__div-table {
    max-width: 800px;
  }

  &__form {
    min-width: 25rem;
  }

  &__user-email {
    white-space: nowrap;
  }
}