@use "../../../../assets/scss" as s;

.user-logins {
  @include s.flexDirection(column);
  flex: auto 1 0;
  border: 1px solid s.$white;
  border-radius: 1.25rem;
  background-color: s.$white;
  margin: 0 0.4rem 2.5rem 0.4rem;
  padding: 1.5rem;
  box-shadow: 2px 5px 15px 5px #0000000d;

  @media only screen and (max-width: 992px) {
    padding: 24px !important;
  }

  &__search-field {
    min-height: 1.4375em;
    padding: 0.375rem;

    .MuiFormControl-root {
      width: 33.333% !important;
      margin: 0 0.5rem 0 0 !important;
    }
  }

  &__search-label {
    .MuiFormLabel-root {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
      font-size: 14px !important;
    }
  }
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}