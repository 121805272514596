@use '../../../assets/scss' as s;

.nav-dash {
    flex: 150px 0 0;

    @media only screen and (max-width: 1200px) {
        margin-left: 24px;
    }

    &__icon-clicked {
        color: s.$lightBlue3;
    }

    &__paths {
        @include s.flexColumnHorAlignment(column, flex-start);
        justify-content: space-evenly;
        background-color: s.$white;
        border-radius: 1.25rem;
        margin: 2.5rem 1.5rem 2.5rem 0;
        padding: 1.5rem 2.5rem;
        box-shadow: 2px 5px 15px 5px #0000000d;
        min-width: 17.188rem;

        @media only screen and (max-width: 1200px) {
            margin-top: 24px;
            padding-left: 24px;
        }
    }

    &__clicked-path {
        background-color: #e3f6ff;
        border-left: 5px solid s.$lightBlue3;
        border-radius: 0 0.625rem 0.625rem 0;
        width: 100%;
    }

    &__paths > a {
        @include s.flexVerAlignment(center);
        color: #000000;
        text-decoration: none;
        font-size: 20px !important;
        padding: 0.8rem 0 0.8rem 0.8rem;
        white-space: nowrap;

        @media only screen and (max-width: 1200px) {
            padding: 0.6rem 0 0.6rem 0.6rem;
        }

    }

    &__headers {
        margin: 0;
        @media screen and (max-width: 1200px){
            font-size: 17px !important;
        }
    }

    &__link-wrapper {
        margin-left: 0.5rem;
    }

    &__paths--inactive {
        border-radius: 0.625rem;
        background: linear-gradient(120deg, rgba(255, 112, 82, 1) 0%, rgba(123, 97, 255, 1) 100%);
        padding: 0.6rem;
        margin: 1rem 1.5rem 1rem 0;
        text-align: center;
        font-size: 1rem;
        font-weight: 700;

        @media screen and (max-width: 1200px){
            box-shadow:
            0 3px 1px -2px rgba(0,0,0,0.2),
            0 2px 2px 0px rgba(0,0,0,0.14),
            0 1px 5px 0px rgba(0,0,0,0.12);
        }
    }

    &__paths--active {
        background: linear-gradient(#fff, #fff) padding-box, /*this is your grey background*/
        linear-gradient(120deg, rgba(255, 112, 82, 1) 0%, rgba(123, 97, 255, 1) 100%) border-box;
        border: 0.0625rem solid transparent;
        border-radius: 0.625rem;
        padding: 0.6rem;
        margin: 1rem 1.5rem 1rem 0;
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
    }

    &__paths--link-inactive {
        text-decoration: none;
        color: s.$white;
    }

    &__paths--link-active {
        background: linear-gradient(120deg, rgba(255, 112, 82, 1) 0%, rgba(123, 97, 255, 1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    &__menubar--margin {
        @media screen and (max-width: 1200px){
            margin-top: 40px
        }
    }
}