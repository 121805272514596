@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*@tailwind base;*/
@tailwind components;
@tailwind utilities;

@layer utilities {
    .btn-box-shadow {
        box-shadow: 2px 5px 15px 5px rgba(0, 0, 0, 0.05);
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Mulish', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
