@mixin footerText($textColor) {
  font-family: 'Mulish', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $textColor;
}


.score-dial {
  position: relative;
  width: 387px;

  @media print {
    width: unset;
  }

  &__chart {
    margin-left: 37px;
  }

  &__avg-score {
    font-family: 'Mulish', serif;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #818A91;
  }

  &__avg-score-circle {
    background: #FFFFFF;
    opacity: 0.6;
  }

  &__at-risk-text {
    @include footerText(#EA3D2F);
  }

  &__great-text {
    @include footerText(#2FA84F);
  }

  &__score-text {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 28px;
    color: #7B61FF;
  }

  &__footer {
    width: 312px;
    margin-left: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__background {
    top: 28px;
    position: absolute;

    @media only screen and (max-width: 662px) {
      left: 14px;
      top: 20px
    }

    @media print {
      top: 28px;
      left: 0;
    }

    svg {
      @media only screen and (max-width: 662px) {
        width: 355px;
      }

      @media print {
        width: 386px;
      }

    }

  }

  &__min-score-circle {
    left: 33px;
    bottom: 96px;
    position: absolute;

    @media only screen and (max-width: 662px) {
      bottom: 28px;
    }

    @media print {
      bottom: 96px;
    }
  }

  &__max-score-circle {
    right: 37px;
    bottom: 96px;
    position: absolute;

    @media only screen and (max-width: 662px) {
      right: 38px;
      bottom: 28px;
    }

    @media print {
      right: 10px;
      bottom: 96px;
    }
  }

  &__viq-score {
    width: 312px;
    margin-left: 35px;
    margin-top: 7px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 662px) {
      margin-top: 2px;
    }

    @media print {
      margin-top: 7px;
    }

  }

  &__viq-score-text {
    font-family: 'Quicksand', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #323595;
    margin-right: 5px;
  }

  &__viq-score-tooltip {
    font-family: 'Mulish', serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: white;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  }

  &__domain {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #323595;
    margin-top: 20px;

    @media only screen and (max-width: 662px) {
      margin-top: 10px;
    }
  }
}