@use '../../../../assets/scss' as s;

.add-tenant {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 1rem;

  &__form {
    width: 20rem;
  }

  &__title {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  &__checkbox-div {
    display: flex;
    margin-bottom: 1rem;
  }

  &__checkbox-styles {
    color: s.$lightBlue3 !important;
    padding: 0;
    background-color: white
  }

  &__checkbox-label {
    align-self: center;
    margin-left: 0.1rem;
  }
}