@use "../../../assets/scss" as s;

.ticket-tracking {
  padding: 1.5rem 2rem 3rem 2rem !important;
  margin-top: 0 !important;

  &__srch-input {
    padding: 0.2rem 0.3rem;
    margin-top: 0.5rem;
    border-radius: 0.4rem;
    border: 0.0625rem solid #bdc7cf;
    font-style: italic;
    width: 15rem;
    height: 30px;

    &--focus:focus {
      background-color: s.$lightGray;
      outline: none;
    }
  }

  &__ticket {
    display: flex;
    border: 1px #ABE4FD solid;
    border-radius: 0.5rem;
    margin-top: 1.5rem;
    padding: 1rem 5rem 1rem 2rem;
    box-shadow: 2px 5px 15px 5px #0000000d;

    @media only screen and (max-width: 992px) {
      flex-direction: column;
      padding-right: 40px !important;
    }
  }

  &--body-left {
    width: 70%;
    margin-right: 2rem;

    @media only screen and (max-width: 992px) {
      width:100%;
      flex-direction: column !important;
    }
  }

  &__domain {
    @include s.flexHorAlignment(flex-start);

    @media only screen and (max-width: 992px) {
      flex-wrap: wrap;
      gap: 12px 24px !important;
    }

  }

  &__link {
    //max-width: 55%;
    height: fit-content;
    color: s.$darkPurple3;

    @media only screen and (min-width: 992px) {
      width: 50% !important;
      margin-right: 24px !important;
    }
  }


  &__companyName {
    display: inline-block;
    font-weight: bold;
    font-size: 1rem;
    color: s.$darkGray;

    &:hover {
      text-decoration: underline;
    }
  }

  &__domain-name {
    font-size: 16px;
    font-weight: 400;
    color: s.$grey;
    //margin-left: 1.5rem;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor: pointer;

    @media only screen and (min-width: 992px) {
      width: 50% !important;
      display:inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis !important;
      cursor: pointer;
    }
  }

  &__description {
    width: fit-content;
    font-size: 16px;
    color: s.$grey;
    min-height: 3.5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    word-break: break-word;
    max-width: fit-content;
  }

  &__ticket-description-modal {
    overflow: auto !important;
    max-width: 40rem;
    max-height: 35rem;
    padding: 0 8px 8px 8px;
    font-size: 18px;
    line-height: 23px;
    white-space: pre-wrap;
    text-align: left;

    @media only screen and (max-width: 992px) {
      font-size: 16px;
    }
  }

  &__contacts {
    //@include s.flexDirection(row);
    display: flex;
    gap: 12px 32px !important;

    @media only screen and (max-width: 992px) {
      display: flex;
      flex-wrap: wrap;
      gap: 12px 32px !important;
    }
  }

  &__button {
    background-color: s.$lightGray;
    padding: 7px 5px 7px 5px;
    border-radius: 8px;
  }

  &__phone-number-button_wrapper {
    min-width: 140px;
  }

  &__phone-icon {
    height: 0.1rem;
  }

  &__phone-number {
    font-family: 'Quicksand', 'sans-serif';
    font-size: 1rem;
  }

  &__email-overflow {
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor: pointer;
  }

  &--button-margin {
    font-family: 'Quicksand', 'sans-serif';
    font-size: 1rem;
  }

  &__score {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
  }

  &__date {
    font-size: 14px;
    margin: 0;
    color: s.$greyMiddle;
    text-align: start;
    font-style: italic;
    white-space: nowrap;

    @media only screen and (max-width: 992px) {
      margin-top: 24px;
    }
  }

  &__source-wrapper {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 992px) {
      justify-content: start;
      gap: 54px;
      width:100%;
    }
  }

  &__src {
    color: s.$greyMiddle;
    font-style: italic;
  }

  &--font-size {
    font-size: 14px;
  }

  &__src--margin {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
    font-family: 'Quicksand', sans-serif;
  }

  &__score-text {
    color: s.$greyMiddle;
    margin-bottom: 0.8rem;
    font-style: italic;
  }

  &__score-number {
    text-align: center;
    color: s.$green;
    font-size: 24px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__btn-wrapper {
    text-align: center;

    @media only screen and (max-width: 992px) {
      text-align: left;
      margin-top: 16px;
    }
  }

  &__score-button {
    height: 2rem;
  }

  &__no-tickets {
    font-weight: 400;
  }
}
