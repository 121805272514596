@media screen and (max-height: 750px) {
  .edit-member-info {
    max-height: 33rem !important;
  }
}

@media screen and (min-height: 751px) and (max-height: 960px) {
  .edit-member-info {
    max-height: 39rem !important;
  }
}

.edit-member-info {

  &__section {
    width: 40%;

    @media only screen and (max-width: 750px) {
      display: flex;
      flex-direction: column;
      width: 80%;
    }

    @media only screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__header {
    font-family: 'Quicksand', 'sans-serif';
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__form {
    width: 40rem;
    font-family: "Mulish", sans-serif;

    @media only screen and (max-width: 750px){
      width: unset;
    }

    @media only screen and (max-width: 450px) {
      padding: 0 1.4rem 1rem 0.4rem;
    }

  }

  &__form-body {
    display: flex;
    justify-content: space-around;

    @media only screen and (max-width: 750px){
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__save-div {
    margin: 0.4rem 0;
  }

  &__save-btn {
    background-color: #2DAAE2 !important;
    font-weight: 700 !important;
    border-radius: 8px !important;
    padding: 6px 25px !important;
  }

  &__save-btn.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;;
  }
}
