@use '../../../assets/scss' as s;

.print {
  @include s.flexHorAlignment(space-between);
  max-width: 45%;
  margin-inline: auto;
  margin-top: 1rem;

  @media only screen and (max-width: 1550px) {
    max-width: 60%;
    font-size: 1.1rem;
  }

  @media only screen and (max-width: 1150px) {
      max-width: 70%;
      font-size: 1.1rem;
  }

  @media only screen and (max-width: 900px) {
      max-width: 75%;
      font-size: 1rem;
  }

  @media only screen and (max-width: 600px) {
    @include s.flexDirection(column);
    align-items: center;
    max-width: 90%;
    font-size: 0.9rem;
  }

  @media print {
      max-width: 90%;
      margin-inline: auto;
      font-size: 0.9rem;
  }

  &__dash_logout {
    display: flex;

    @media only screen and (max-width: 600px) {
      @include s.flexDirection(column);
    }
  }

  &__dashboard {
    margin: 0;

    @media only screen and (max-width: 600px) {
      margin: 0.5rem 0;
      width: 100%;
    }
  }
}

