@font-face {
  font-family: 'Maven Pro', sans-serif;
}

.style_guide_main {
  font-family: 'Maven Pro', sans-serif;
  width: 35%;
  margin-left: 2rem;
  background-color: white;
  border-radius: 3rem 3rem;
  padding: 1rem;
}