.not_found_text-content {
  text-align: center;

  .not_found__oops {
    color: #A6A6A6;

    @media only screen and (max-width: 900px) {
      font-size: 1rem;
      text-align: center;
    }
  }
}