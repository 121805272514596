@use '../../../TicketTracking/TicketModal';
@use '../../../../../assets/scss' as s;

@media screen and (max-height: 800px) {
  .member-modifier {
    max-height: 33rem !important;
  }
}

@media screen and (min-height: 801px) and (max-height: 960px) {
  .member-modifier {
    max-height: 39rem !important;
  }
}

.member-modifier {
  @extend .modal-window;
  max-height: fit-content;
  overflow-y: auto;

  @media only screen and (max-width: 450px) {
    padding: 0 1.4rem 1rem 0.4rem;
  }

  &__main {
    @extend .modal-main;
    text-align: start;
  }

  &__modal-title {
    text-align: center;
    font-family: 'Quicksand', 'system-ui';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    margin-top: 0.5rem;

    @media only screen and (max-width: 450px) {
      margin-bottom: 12px;
    }
  }

  &__form {
    display: flex;
    justify-content: space-around;
    width: 42rem;

    @media only screen and (max-width: 900px) {
      display: flex;
      flex-wrap: wrap;
      width: unset;
      }
  }

  &__section {
    width: 40%;

    @media only screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      width: 80%;
    }

    @media only screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__address-header {
    margin-left: 34px;

    @media only screen and (max-width: 900px) {
      margin-left: 48px;
    }

    @media only screen and (max-width: 500px) {
      margin-left: 0;
    }
  }

  &__mailing-wrapper {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 1rem;

    @media only screen and (max-width: 900px) {
      margin: 0 0 1rem;
    }
  }

  &__mailing-info {
    display: flex;
    justify-content: space-around;

    @media only screen and (max-width: 900px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__submit-wrapper {
    min-height: 2.3rem;
    width: fit-content;
    float: right;
    padding-bottom: 2rem;
    margin-right: 2rem;

    @media only screen and (max-width: 900px) {
      margin-right: 48px;
    }

    @media only screen and (max-width: 500px) {
      margin-right: 0;
    }
  }

  &__submit {
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    border-radius: 8px !important;
    width: 6rem !important;
    background-color: #2DAAE2 !important;
    color: white;
    text-decoration: none;
    font-weight: bold !important;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  &__form-error {
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 4px 14px 0;
    font-style: italic;
  }

  &__form-control {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-bottom: 1rem;
  }

  &__form-control--phone {
    margin-bottom: 1.25rem;
  }

  &__form-input {
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border-radius: 0.4rem;
    border: 0.0625rem solid #bdc7cf;
    width: 20rem;
  }

  &__form-select {
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border-radius: 0.4rem;
    border: 0.0625rem solid #bdc7cf;
    width: 21.15rem;
  }

  &__modal {
    align-items: center;
    text-align: center;
  }

  &__modal {
    padding: 0 2rem 1rem 2rem;

  }

  &__title {
    margin: 0 !important;
  }

  &__content {
    margin-bottom: 0 !important;
  }
}

.react-tel-input .form-control {
  height: 2.5rem;
  width: inherit !important;
}

.error {
  input {
    border-color: #ff0000 !important;
  }
}

.error .form-control:focus {
  box-shadow: 0 0 0 1px #d32f2f !important;
}

select:invalid {
  color: green !important;
}
